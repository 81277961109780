import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.trim.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var myFooter = function myFooter() {
  return import("./common/myFooter");
};

var card = function card() {
  return import("./common/card");
};

var proButton = function proButton() {
  return import("./common/proButton");
};

export default {
  components: {
    myFooter: myFooter,
    card: card,
    proButton: proButton
  },
  data: function data() {
    return {
      friendList: {},
      friend: {
        title: "",
        introduction: "",
        cover: "",
        url: ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getFriends();
  },
  mounted: function mounted() {},
  methods: {
    clickLetter: function clickLetter() {
      if (document.body.clientWidth < 700) {
        $(".form-wrap").css({
          "height": "1000px",
          "top": "-200px"
        });
      } else {
        $(".form-wrap").css({
          "height": "1150px",
          "top": "-200px"
        });
      }
    },
    submitFriend: function submitFriend() {
      var _this = this;

      if (this.$common.isEmpty(this.$store.state.currentUser)) {
        this.$message({
          message: "请先登录！",
          type: "error"
        });
        return;
      }

      if (this.friend.title.trim() === "") {
        this.$message({
          message: "你还没写名称呢~",
          type: "warning"
        });
        return;
      }

      if (this.friend.introduction.trim() === "") {
        this.$message({
          message: "你还没写简介呢~",
          type: "warning"
        });
        return;
      }

      if (this.friend.cover.trim() === "") {
        this.$message({
          message: "你还没设置封面呢~",
          type: "warning"
        });
        return;
      }

      if (this.friend.url.trim() === "") {
        this.$message({
          message: "你还没写网址呢~",
          type: "warning"
        });
        return;
      }

      this.$http.post(this.$constant.baseURL + "/webInfo/saveFriend", this.friend).then(function (res) {
        $(".form-wrap").css({
          "height": "447px",
          "top": "0"
        });

        _this.$message({
          type: 'success',
          message: '提交成功，待管理员审核！'
        });
      }).catch(function (error) {
        _this.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    clickFriend: function clickFriend(path) {
      window.open(path);
    },
    getFriends: function getFriends() {
      var _this2 = this;

      this.$http.get(this.$constant.baseURL + "/webInfo/listFriend").then(function (res) {
        if (!_this2.$common.isEmpty(res.data)) {
          _this2.friendList = res.data;
        }
      }).catch(function (error) {
        _this2.$message({
          message: error.message,
          type: "error"
        });
      });
    }
  }
};